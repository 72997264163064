// https://chir.ag/projects/name-that-color
$dune: #373534; // body-color, secondary
$chicago: #585554; // semi-dark
$alto: #D8D8D8;
$alabaster: #FAFAFA; // body-bg
$alabaster_2: #F7F7F7;
$gallery: #F0F0F0; // border-color
$fern-green: #4A7A39;
$hippie-green: #5A9346; // primary
$mongoose: #B79A80; // tertiary
$rodeo-dust: #C5B19E;
$cloudy: #A49F9D; // cloudy
$nobel: #B4B3B3;
$mako: #464C51;
$contessa: #C56363; // danger
$malibu: #52E7FA; // info

$text-primary: $dune;
$text-secondary: $cloudy;
$text-tertiary: $mongoose;
$menu-color: $chicago;
$menu-active: $fern-green;
$switch-bg: $alabaster_2;
$switch-indicator-bg: $alto;
$list-style-color: $rodeo-dust;
$list-items-color: $chicago;
$semi-dark: $chicago;

$header-bg: white;
$sidebar-bg: white;
$header-height: 100px;

$font-weight-extra-bold: 900;

// Bootstrap Overwrite
$font-family-base: 'din-next-lt';
$font-weight-bold: 500;

$body-color: $text-primary;
$body-bg: $alabaster;
$primary: $hippie-green;
$secondary: $text-primary;
$tertiary: $text-tertiary;
$danger: $contessa;
$info: $malibu;
$theme-colors: ("tertiary": $tertiary, "cloudy": $cloudy, "semi-dark": $chicago);
$border-color: $gallery;

$border-radius: 0;
$btn-padding-x: 1.5rem;
$btn-padding-y: 1rem;
$btn-border-width: 2px;
$btn-font-weight: $font-weight-bold;

$navbar-light-color: $text-primary;

$table-head-color: $text-tertiary;
$table-head-bg: transparent;
$table-color: $text-secondary;
$table-cell-padding: 1.25rem;

$modal-content-border-radius: 0;
$modal-content-border-width: 0;
$modal-content-bg: $body-bg;
$modal-inner-padding: 2rem;

$dropdown-color: $menu-color;
$dropdown-link-color: $dropdown-color;

$pagination-active-bg: white;
$pagination-color: $text-secondary;
$pagination-border-color: $border-color;
$pagination-disabled-color: $text-secondary;
$pagination-disabled-border-color: $border-color;
$pagination-active-color: $text-tertiary;
$pagination-active-border-color: $border-color;
$pagination-focus-box-shadow: 0 0 0 .2rem rgba($pagination-active-color, .25);

$input-color: $text-secondary;
$input-padding-x: 1.5rem;
$input-padding-y: .75rem;
$form-group-margin-bottom: 1.5rem;
$input-placeholder-color: $text-secondary;
$input-line-height: 1.75;

$progress-border-radius: .75rem;
$progress-bg: white;
$progress-height: 1.375rem;

$nav-tabs-link-active-color: $primary;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-border-color: transparent;
$nav-tabs-link-active-bg: transparent;

@import "project-variables";

@import "bootstrap-v4-rtl/scss/functions";
@import "bootstrap-v4-rtl/scss/variables";

// ng-select Overwrite
$ng-select-highlight: $primary;
$ng-select-primary-text: $input-color;
$ng-select-border: $input-border-color;
$ng-select-border-radius: $border-radius;
$ng-select-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba($ng-select-highlight, 0.1);
$ng-select-placeholder: $input-placeholder-color;
$ng-select-height: $input-height;

$zindex-modal-backdrop: 1004 !important;
$zindex-modal: 1005;
